import store from '@/store';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import Login from "@/views/Login.vue";
import Intro from "@/views/Intro.vue";
import Register from "@/views/Register.vue";
import EventTypeChoose from "@/views/EventTypeChoose.vue";
import TableSeatings from "@/views/TableSeatings.vue";
import PasswordReset from "@/views/PasswordReset.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/rsvp'
  },
  {
    path: '/intro',
    component: Intro
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/choose-event',
    name: 'choose-event',
    component: EventTypeChoose
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        store.dispatch("logout").then(() => ({}));
        next({path: "/intro"})
      }
    }
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3.vue')
      },
      {
        path: 'rsvp',
        component: () => import('@/views/RsvpList.vue')
      },
      {
        path: 'design',
        component: () => import('@/views/DesignSelect.vue')
      },
      {
        path: 'invitation',
        component: () => import('@/views/InvitationEdit.vue')
      },
      {
        path: 'expenses',
        name: 'expenses',
        component: () => import('@/views/Expenses.vue')
      },
      {
        path: 'incomes-and-expenses',
        component: () => import('@/views/IncomeAndExpenses.vue')
      },
      {
        path: 'message-scheduling',
        component: () => import('@/views/MessagesScheduling.vue')
      },
      {
        path: 'seating',
        name: 'seating',
        component: TableSeatings
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf('/login') === -1 && to.fullPath.indexOf('/intro') === -1 && to.fullPath.indexOf('/register') === -1 && to.fullPath.indexOf('/password-reset') === -1) {
    if (!store.state.token) {
      next('/intro');
    }else{
      next();
    }
  }else{
    next();
  }
});

export default router
