import { createStore } from 'vuex'
import http from "@/http";
import InvitationInfo from "@/interfaces/InvitationInfo";
import InvitationDesign from "@/interfaces/InvitationDesign";
import {alertController} from "@ionic/vue";
import TableSeating from "@/interfaces/TableSeating";

let user = localStorage.getItem('user')
if(user){
  user = JSON.parse(user)
}

const invitees: Array<any> = []

const invitationDesign: InvitationDesign = {
  id: '',
  name: '',
  settings: {}
}

const invitationInfo: InvitationInfo = {
  id: null,
  title: '',
  subtitle: '',
  eventDate: '',
  eventStages: [],
  hebrewDate: '',
  text: '',
  locationName: '',
  location: {lat: 0, long: 0},
  address: '',
  mapLink: '',
  wazeLink: '',
  rsvpTitle: '',
  rsvpText: '',
  images: [],
  rsvpImages: [],
  invitationDesignId: null
}

const tableSeatings: Array<TableSeating> = [];

const account: any = null;

const expenses: Array<any> = []

const incomes: Array<any> = []

export default createStore({
  state: {
    token: localStorage.getItem('token'),
    user: user,
    invitationDesigns: [invitationDesign],
    invitationInfo,
    invitees,
    inviteesCount: null,
    loggedOutAlerted: false,
    account,
    expenses,
    incomes,
    expensesTotal: 0,
    incomesTotal: 0,
    tableSeatings
  },
  getters: {
    account({account}){
      return account
    },
    showToolbar({token}){
      return !!token
    },
    selectedInvitationDesign({invitationDesigns, invitationInfo}){
      if(invitationInfo){
        const index = invitationDesigns.findIndex(d => d.id === invitationInfo.invitationDesignId)
        if(index != -1){
          return invitationDesigns[index]
        }
      }
      return null
    },
    loggedOutAlerted({loggedOutAlerted}){
      return loggedOutAlerted
    },
    invitationInfo({invitationInfo}){
      return invitationInfo
    },
    inviteesCount({inviteesCount}){
      return inviteesCount
    },
    expenses({expenses}){
      return expenses
    },
    incomes({incomes}){
        return incomes
    },
    expensesTotal({expensesTotal}){
      return expensesTotal
    },
    incomesTotal({incomesTotal}){
        return incomesTotal
    },
    tableSeatings({tableSeatings}){
      return tableSeatings
    },
    invitees({invitees}){
      return invitees
    }
  },
  mutations: {
    loggedOutAlerted(state, loggedOutAlerted){
      state.loggedOutAlerted = loggedOutAlerted
    },
    token(state, token){
      if(token){
        localStorage.setItem('token', token)
      }else{
        localStorage.removeItem('token')
      }
      state.token = token
    },
    user(state, user){
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    invitationDesigns(state, invitationDesigns){
      state.invitationDesigns = invitationDesigns
    },
    invitationDesign(state, invitationDesignId){
      state.invitationInfo.invitationDesignId = invitationDesignId
    },
    invitationInfo(state, info){
      state.invitationInfo = {...state.invitationInfo,...info}
    },
    invitationInfoId(state, id){
      state.invitationInfo.id = id
    },
    invitationInfoTitle(state, title){
      state.invitationInfo.title = title
    },
    invitationInfoSubtitle(state, subtitle){
      state.invitationInfo.subtitle = subtitle
    },
    invitationInfoEventDate(state, eventDate){
      state.invitationInfo.eventDate = eventDate
    },
    invitationInfoHebrewDate(state, hebrewDate){
      state.invitationInfo.hebrewDate = hebrewDate
    },
    invitationInfoText(state, text){
      state.invitationInfo.text = text
    },
    invitationInfoLocationName(state, locationName){
      state.invitationInfo.locationName = locationName
    },
    invitationInfoLocation(state, {lat, long}){
      state.invitationInfo.location.lat = lat
      state.invitationInfo.location.long = long
    },
    invitationInfoAddress(state, address){
      state.invitationInfo.address = address
    },
    invitationInfoMapLink(state, mapLink){
      state.invitationInfo.mapLink = mapLink
    },
    invitationInfoWazeLink(state, wazeLink){
      state.invitationInfo.wazeLink = wazeLink
    },
    invitationInfoRsvpTitle(state, rsvpTitle){
      state.invitationInfo.rsvpTitle = rsvpTitle
    },
    invitationInfoRsvpText(state, rsvpText){
      state.invitationInfo.rsvpText = rsvpText
    },
    invitationInfoImages(state, images){
      state.invitationInfo.images = images
    },
    invitationInfoAddImages(state, image){
      state.invitationInfo.images = [image]
    },
    invitationInfoRsvpImages(state, images){
      state.invitationInfo.rsvpImages = images
    },
    invitationInfoAddRsvpImages(state, image){
      state.invitationInfo.rsvpImages = [image]
    },
    setInvitees(state, invitees){
      state.invitees = invitees
    },
    inviteesCount(state, inviteesCount){
      state.inviteesCount = inviteesCount
    },
    addInvitee(state, invitee){
      const index = state.invitees.findIndex(inv => inv.id === invitee.id)
      if(index === -1){
        state.invitees.push(invitee)
      }else{
        state.invitees[index] = invitee
      }
    },
    removeInvitee(state, invitee){
      const index = state.invitees.findIndex(inv => inv.id === invitee.id)
      if(index !== -1){
        state.invitees.splice(index, 1)
      }
    },
    account(state, account){
      state.account = account
    },
    updateAccount(state, account){
      state.account = {...state.account, ...account}
    },
    expenses(state, expenses){
      state.expenses = expenses
    },
    incomes(state, incomes){
        state.incomes = incomes
    },
    expensesTotal(state, expensesTotal){
      state.expensesTotal = expensesTotal
    },
    incomesTotal(state, incomesTotal){
        state.incomesTotal = incomesTotal
    },
    addExpense(state, expense){
      const index = state.expenses.findIndex(exp => exp.id === expense.id)
      if(index === -1){
        state.expenses.unshift(expense)
      }else{
        state.expenses[index] = expense
      }
    },
    addIncome(state, income){
        const index = state.incomes.findIndex(inc => inc.id === income.id)
        if(index === -1){
          state.incomes.unshift(income)
        }else{
          state.incomes[index] = income
        }
    },
    removeExpense(state, expense){
      const index = state.expenses.findIndex(exp => exp.id === expense.id)
      if(index !== -1){
        state.expenses.splice(index, 1)
      }
    },
    removeIncome(state, income){
        const index = state.incomes.findIndex(inc => inc.id === income.id)
        if(index !== -1){
          state.incomes.splice(index, 1)
        }
    },
    tableSeatings(state, tableSeatings){
      state.tableSeatings = tableSeatings
    },
    addTableSeating(state, tableSeating){
      const index = state.tableSeatings.findIndex(ts => ts.id === tableSeating.id)
      if(index > -1){
        state.tableSeatings[index] = tableSeating
      }else{
        state.tableSeatings.push(tableSeating)
      }
    },
    removeTableSeating(state, tableSeating){
      const index = state.tableSeatings.findIndex(ts => ts.id === tableSeating.id)
      if(index > -1){
        state.tableSeatings.splice(index, 1)
      }
    }
  },
  actions: {
    inviteesLoad({commit}, page = 1){
      return new Promise((resolve, reject) => {
        http.get('invitees', {params: {page}}).then(({data}) => {
          if(data.invitees){
            commit('setInvitees', data.invitees)
            commit('inviteesCount', data.count)
            resolve(data.invitees)
          }else{
            reject(data)
          }
        }, (e) => {
          reject(e)
        })
      })
    },
    async inviteeSave({commit}, invitee){
      const path = ['invitees'];
      if(invitee.id){
        path.push(invitee.id)
      }

      try {
        const {data} = await http.post(path.join('/'), invitee)

        if(data.invitee){
          commit('addInvitee', data.invitee)
          return true
        }
        return false
      }catch (e){
        console.warn(e)
      }
      return false
    },
    async inviteesSave({dispatch}, invitees){
      const {data} = await http.post('invitees/import', {invitees})
      if(data.createdCount && data.totalCount){
        dispatch('inviteesLoad')
      }
      return data
    },
    async inviteeRemove({commit}, invitee){

      if(invitee.id){
        const path = ['invitees'];

        path.push(invitee.id)

        if(invitee){
          commit('removeInvitee', invitee)
        }

        try{
          await http.delete(path.join('/'), invitee)
          return true
        }catch (e){
          commit('addInvitee', invitee)
          console.warn(e)
        }
      }
      return false
    },
    invitationInfoLoad({commit, getters}){
      if(getters.invitationInfo && getters.invitationInfo.id){
        return new Promise((resolve, reject) => {
          http.get(`invitation/${getters.invitationInfo.id}`).then(({data}) => {
            if(data.invitation){
              commit('invitationInfo', data.invitation)
              resolve(data.invitation)
            }else{
              reject('invitation info not found')
            }
          }, (e) => {
            reject(e)
          })
        })
      }
      return Promise.reject('invitation info id dont exists')
    },
    async invitationInfoSave({state, getters}){
      try{
        const {status} = await http.post(`invitation/${getters.invitationInfo.id}`, state.invitationInfo)
        return status === 200
      }catch (e){
        console.warn(e)
      }
      return false
    },
    selectInvitationDesign({commit}, invitationDesignId){
      commit('invitationDesign', invitationDesignId)
      return http.post('set-invite-design', {id: invitationDesignId})
    },
    async loadData(state){
      if(this.state.token){
        const {data} = await http.get('general-data')
        if(data.invitationDesigns){
          state.commit('invitationDesigns', data.invitationDesigns)
        }
        if(data.account){
          state.commit('account', data.account)
          if(data.account.invitation){
            state.commit('invitationInfo', data.account.invitation)
          }
        }
        return data
      }
      return null
    },
    async login({commit,dispatch}, credentials){
      const {status, data} = await http.post('auth/token', credentials)
      if(data.token){
        commit('token', data.token)
        dispatch('loadData')
      }
      return {status,data}
    },
    logout(state){
      state.commit('token', null)
    },
    async sendResetPasswordOtp(state, identifier){
      const {status} = await http.post('auth/password-reset', identifier)
      return status === 200
    },
    async resetPassword({commit, dispatch}, userInfo){
      const {status, data} = await http.put('auth/password-reset', userInfo)
      if(data.token){
        commit('token', data.token)
        dispatch('loadData')
      }
      return status === 200
    },
    profile(state){
      return new Promise((resolve, reject) => {
        http.get('user').then(({data}) => {
          if(data.user){
            state.commit('user', data.user)
            resolve(data.user)
          }else{
            reject('user not found')
          }
        }, (e) => {
          reject(e)
        })
      })
    },
    async register({commit, dispatch}, {user}){
      const {data, status} = await http.post('auth/register', user)
      if(data.user){
        commit('user', data.user)
      }
      if(data.token){
        commit('token', data.token)
        dispatch('loadData')
      }
      return {data, status}
    },
    async updateUserInfo({commit}, {userInfo}){
      const {data} = await http.post('profile', {userInfo})
      if(data.user){
        commit('user', data.user)
      }
    },
    async updateAccount({commit}, account){
      const {data} = await http.post('account', account)
      if(data.account){
        commit('updateAccount', data.account)
      }
    },
    async loggedOutAlert({commit}){
      const alert = await alertController.create({
        message: 'התנתקת מהמערכת.',
        buttons: [
          {
            text: 'התחברות',
            role: 'confirm'
          }
        ],
        backdropDismiss: false
      })
      await alert.present()
      const {role} = await alert.onDidDismiss()
      commit('loggedOutAlerted', false)
      return role === 'confirm'
    },
    async expensesLoad({commit}){
      const {data} = await http.get('expenses')
      if(data.expenses){
        commit('expenses', data.expenses)
      }
      if(data.total){
        commit('expensesTotal', data.total)
      }
    },
    async incomesLoad({commit}){
        const {data} = await http.get('incomes')
        if(data.incomes){
            commit('incomes', data.incomes)
        }
        if(data.total){
            commit('incomesTotal', data.total)
        }
    },
    async expenseSave({commit}, expense){
      const path = ['expenses'];
      if(expense.id){
        path.push(expense.id)
      }

      const {data} = await http.post(path.join('/'), expense)

      if(data.expense){
        commit('addExpense', data.expense)
      }
      if(data.total){
        commit('expensesTotal', data.total)
      }
    },
    async incomeSave({commit}, income){
        const path = ['incomes'];
        if(income.id){
            path.push(income.id)
        }

        const {data} = await http.post(path.join('/'), income)

        if(data.income){
            commit('addIncome', data.income)
        }
        if(data.total){
            commit('incomesTotal', data.total)
        }
    },
    async expenseRemove({commit}, expense){

      if(expense.id){
        const path = ['expenses'];

        path.push(expense.id)

        if(expense){
          commit('removeExpense', expense)
        }

        try{
          await http.delete(path.join('/'), expense)
          return true
        }catch (e){
          commit('addExpense', expense)
          console.warn(e)
        }
        return false
      }
      return true
    },
    async incomeRemove({commit}, income){
        if(income.id){
            const path = ['incomes'];

            path.push(income.id)

            if(income){
            commit('removeIncome', income)
            }

            try{
            await http.delete(path.join('/'), income)
            return true
            }catch (e){
            commit('addIncome', income)
            console.warn(e)
            }
        }
        return false
    },
    async tableSeatingsLoad({commit}){
      const {data} = await http.get('seatings')
      if(data.seatings){
        commit('tableSeatings', data.seatings)
      }
      return data
    },
    async tableSeatingsSave({commit}, tableSeating){
      const path = ['seatings'];
      if(tableSeating.id){
        path.push(tableSeating.id)
      }
      const {data} = await http.post(path.join('/'), tableSeating)
      if(data.seating){
        commit('addTableSeating', data.seating)
        return data.seating
      }
      return false
    },
    async tableSeatingsRemove({commit}, tableSeating){
      if(tableSeating.id){
        const {status} = await http.delete(`seatings/${tableSeating.id}`)
        if(status === 200){
          commit('removeTableSeating', tableSeating)
          return true
        }
      }
      return false
    },
    async messageTemplateSave(state, messageTemplate){
      const path = ['message-template'];
      if(messageTemplate.id){
        path.push(messageTemplate.id)
      }
      const {status, data} = await http.post(path.join('/'), messageTemplate)
      if(data.messageTemplate){
        //commit('messageTemplate', data.messageTemplate)
      }
      return {status, data}
    }
  },
  modules: {
  }
})
