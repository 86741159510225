
import {defineComponent} from "vue";
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline, closeOutline, trashOutline} from "ionicons/icons";
import TableSeating from "@/interfaces/TableSeating";

export default defineComponent({
    name: "EditTable",
    props: {
        input: {
            required: false,
            default: null
        }
    },
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonItem,
        IonList,
        IonInput,
        IonLabel,
        IonSelect,
        IonSelectOption
    },
    data(){

        const number = this.$store.getters.tableSeatings.length

        const emptyTable: TableSeating = {
            name: `שולחן ${(number + 1)}`,
            number: (number + 1),
            seatsAmount: 10,
            type: 'square',
        }

        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline,
                closeOutline,
                trashOutline
            },
            table: emptyTable,
            initialTable: emptyTable
        }
    },
    methods: {
        async back(){
            if(JSON.stringify(this.table) !== JSON.stringify(this.initialTable)){
                const alert = await alertController.create({
                    message: 'לסגור ללא שמירת שינויים ?',
                    buttons: [
                        {
                            text: 'אישור',
                            role: 'confirm'
                        },
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        }
                    ]
                })
                await alert.present()
                const {role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(null, 'back')
                }
            }else{
                await modalController.dismiss(null, 'back')
            }
        },
        save(){
            modalController.dismiss(Object.assign({}, this.table), 'save')
        },
        async remove(){
            const alert = await alertController.create({
                message: 'למחוק שולחן זה?',
                buttons: [
                    {
                        text: 'כן',
                        role: 'confirm'
                    },
                    {
                        text: 'לא',
                        role: 'cancel'
                    }
                ]
            })

            await alert.present()
            const {role} = await alert.onDidDismiss()
            if(role === 'confirm'){
                await modalController.dismiss(Object.assign({}, this.table), 'remove')
            }
        }
    },
    beforeMount() {
        if(this.input){
            this.table = Object.assign(this.table, this.input)
            this.initialTable = Object.assign({}, this.table)
        }
    }
})
